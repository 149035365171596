import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {closeDrawer} from "../../actions/drawerActions";
import {getTemplateReferences, clearTemplateReferences} from "../../actions/templateListActions";
import statusFormatter from "../../utilities/StatusFormatter";
import * as TEMPLATE from "../../constants/templateConstants";
import ArgoAppBar from "../common/ArgoAppBar";
import style from "../../blueTheme";


class ReferencesDrawer extends Component {

    componentWillUnmount() {
        this.props.clearTemplateReferences();
    }

    formatRow = (reference, index) => {
        // debugger
        let questionnaire = reference.questionnaireTemplateReferenceDTO,
            category  = reference.questionCategoryTemplateReferenceDTO,
            group = reference.questionGroupTemplateReferenceDTO;

        let showGroups = (this.props.templateType === TEMPLATE.TYPE.QUESTION);
        let showCategory = (this.props.templateType === TEMPLATE.TYPE.QUESTION || this.props.templateType === TEMPLATE.TYPE.GROUP);

        return (<TableRow key={index}>
            <TableCell style={{height: '10px', padding: '0 0 0 16px'}}>{questionnaire && statusFormatter(questionnaire, index)}</TableCell>
            <TableCell colSpan="2" style={{height: '10px',
                width: '164px',
                padding: '0 16px',
                whiteSpace: 'nowrap',
                wordWrap: 'break-word',
                overflow: "hidden",
                textOverflow: 'ellipsis'}}>
                {questionnaire && <div title={questionnaire.name}>{questionnaire.name}</div>}
            </TableCell>
            <TableCell colSpan="2" style={{height: '10px',
                width: '164px',
                padding: '0 16px',
                whiteSpace: 'nowrap',
                wordWrap: 'break-word',
                overflow: "hidden",
                textOverflow: 'ellipsis'}}>
                {questionnaire && <div title={questionnaire.nameQualifier}>{questionnaire.nameQualifier}</div>}
            </TableCell>
            <Divider orientation="vertical" flexItem style={{height: '41px'}}/>
            {showCategory && <TableCell style={{height: '10px', padding: '0 0 0 16px'}}>{category && statusFormatter(category, index)}</TableCell>}
            {showCategory && <TableCell colSpan="2" style={{height: '10px',
                width: '164px',
                padding: '0 16px',
                whiteSpace: 'nowrap',
                wordWrap: 'break-word',
                overflow: "hidden",
                textOverflow: 'ellipsis'}}>{category && <div title={category.name}>{category.name}</div>}</TableCell>}
            {showCategory && <TableCell colSpan="2" style={{height: '10px',
                width: '164px',
                padding: '0 16px',
                whiteSpace: 'nowrap',
                wordWrap: 'break-word',
                overflow: "hidden",
                textOverflow: 'ellipsis'}}>{category && <div title={category.nameQualifier}>{category.nameQualifier}</div>}</TableCell>}
            {showCategory && <Divider orientation="vertical" flexItem style={{height: '41px'}}/>}
            {showGroups && <TableCell style={{height: '10px', padding: '0 0 0 16px'}}>{group && statusFormatter(group, index)}</TableCell>}
            {showGroups && <TableCell colSpan="2" style={{height: '10px',
                width: '164px',
                padding: '0 16px',
                whiteSpace: 'nowrap',
                wordWrap: 'break-word',
                overflow: "hidden",
                textOverflow: 'ellipsis'}}>{group && <div title={group.name}>{group.name}</div>}</TableCell>}
            {showGroups && <TableCell colSpan="2" style={{height: '10px',
                width: '164px',
                padding: '0 16px',
                whiteSpace: 'nowrap',
                wordWrap: 'break-word',
                overflow: "hidden",
                textOverflow: 'ellipsis'}}>{group && <div title={group.nameQualifier}>{group.nameQualifier}</div>}</TableCell>}
        </TableRow>);
    };

    columnWidth(categories, groups, isQualifier = false) {
        if (categories && groups) {
            return isQualifier ? '9%' : '21%';
        } else if (categories) {
            return isQualifier ? '13.5%' : '31.5%';
        }
        return isQualifier ? '27%' : '63%';
    }

    render() {

        let showGroups = (this.props.templateType === TEMPLATE.TYPE.QUESTION);
        let showCategory = (this.props.templateType === TEMPLATE.TYPE.QUESTION || this.props.templateType === TEMPLATE.TYPE.GROUP);
        let preparedTableStyle = {...style.tableGrid.wrapperStyle};

        return (
            <ClickAwayListener onClickAway={this.props.closeDrawer}>
                <div style={{height: '100%'}}>
                    <ArgoAppBar
                        title={"References: "}
                        titleFor={this.props.row.name}
                        qualifierFor={this.props.row.nameQualifier}
                        showMenuArrowButton="LEFT"
                        onLeftIconButtonTouchTap={this.props.closeDrawer}
                        isDrawer={true}
                        width={referencesDrawerProps.width}
                    />

                    <div style={{height: '64px'}}></div>

                    <div id="ReferencesDrawer-container-div" style={{padding: "30px", width: 'auto'}}>
                        <TableContainer id="TableGrid-TableCotainer" component={Paper}
                                        style={{boxShadow: '0px 0px 5px rgba(0,0,0,.2)'}}>

                            <Table
                                wrapperStyle={preparedTableStyle}
                                headerStyle={style.tableGrid.headerStyle}
                                bodyStyle={style.tableGrid.bodyStyle}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{width: '20px', paddingRight: '0'}}>Status</TableCell>
                                        <TableCell colSpan="2" style={{width: this.columnWidth(showCategory, showGroups)}}>Questionnaire</TableCell>
                                        <TableCell colSpan="2" style={{width: this.columnWidth(showCategory, showGroups, true)}}>Qualifier</TableCell>
                                        <Divider orientation="vertical" flexItem style={{height: '57px'}}/>
                                        {showCategory &&
                                            <TableCell style={{width: '20px', paddingRight: '0'}}>Status</TableCell>}
                                        {showCategory && <TableCell colSpan="2"
                                                                    style={{width: this.columnWidth(showCategory, showGroups)}}>Category</TableCell>}
                                        {showCategory && <TableCell colSpan="2"
                                                                    style={{width: this.columnWidth(showCategory, showGroups, true)}}>Qualifier</TableCell>}
                                        {showCategory &&
                                            <Divider orientation="vertical" flexItem style={{height: '57px'}}/>}
                                        {showGroups &&
                                            <TableCell style={{width: '20px', paddingRight: '0'}}>Status</TableCell>}
                                        {showGroups && <TableCell colSpan="2"
                                                                  style={{width: this.columnWidth(showCategory, showGroups)}}>Group</TableCell>}
                                        {showGroups && <TableCell colSpan="2"
                                                                  style={{width: this.columnWidth(showCategory, showGroups, true)}}>Qualifier</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody
                                    sx={{
                                        "& .MuiTableRow-root:hover": {
                                            backgroundColor: "#F5F5F5"
                                        }
                                    }}>
                                    {this.props.references.map((reference, index) => {
                                        return this.formatRow(reference, index);
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

ReferencesDrawer.propTypes = {
    templateType: PropTypes.string,
    templateId: PropTypes.number,
    title: PropTypes.string,
    row: PropTypes.object
};

function mapStateToProps(state) {
    return {
        references: state.references
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer, getTemplateReferences, clearTemplateReferences}, dispatch);
}

const referencesDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferencesDrawer);
export {referencesDrawerProps};
