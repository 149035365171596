import {STORAGE_TOKEN} from "../constants/systemConstants";
import {errorResponse} from "./errorResponse";
import 'whatwg-fetch'

export const axiosWrap = function(method, url, data = null, options = {}) {

    let config = {
        method: method,
        headers: {
            "Content-Type": "application/json;charset=UTF-8"
        },
        ...options
    };

    if(data) {
        if (typeof data === 'string' || data instanceof String) {
            config.body = data;
        }
        else {
            config.body = JSON.stringify(data);
        }
    }

    return new Promise( (resolve, reject) => {
        const authorizationToken = localStorage.getItem(STORAGE_TOKEN);

        if(authorizationToken) {
            config.headers["Authorization"] = "Bearer " + authorizationToken;
        }

        try {
            fetch(url, config).then(res => {
                console.log("Fetch Success.");

                // console.log("res:", res);
                if (res.status === 200) {

                    res.json().then(body => {
                        resolve(body);
                    });

                }
                else {
                    if(res.status < 400)
                    {
                        resolve({status: res.status});
                    } else if(res.status === 401) {
                        errorResponse(res);
                        // console.log("res:", res);
                    } else {
                        console.log("Failure mid");
                        console.log(res.status);
                        errorResponse({status: res.status});
                        //reject("Request failed:", url);
                    }
                }

            }, error => {
                console.log("Failure lower mid");
                console.log(error);
                errorResponse(error); //TODO this needs to get the body of the error, this doesn't currently get called
                reject("Request failed:", url);
            });
        } catch (error) {
            console.log("Failure");
            console.log(error);
            errorResponse(error); //TODO this needs to get the body of the error, this doesn't currently get called
            reject("Request failed:", url);
        }
    });
};

export const GET = "get";
export const PUT = "put";
export const POST = "POST";
