import React from "react";
import Grid from '@mui/material/Grid';
import * as custom from "./getCustomTypography";
import * as portalShared from "../shared/metadataConstantsAndUtilities"


export function getCategoryTitleBar(qnr, categoryTitleObj, itemObj, convertedVariant, uploadedImageStyles, objId) {

    let htmlObj = <div></div>;

    let bColor = portalShared.getColor(categoryTitleObj.categoryCustomObj.colorObj);
    let bOpacity = categoryTitleObj.categoryCustomObj.colorObj.opacity;
    let bHeight = categoryTitleObj.categoryCustomObj.height; // Text size can override height


    if (!categoryTitleObj.useCustomLeft && !categoryTitleObj.useCustomCenter && !categoryTitleObj.useCustomRight) {

        // Title Bar NO Text, Could be used as a simple divider
        htmlObj = <Grid key={"CatTitle-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px"}}>
            <div style={{width: '100%', display: 'flex'}}></div>
        </Grid>;
    }
    else if (categoryTitleObj.useCustomLeft && categoryTitleObj.useCustomCenter && categoryTitleObj.useCustomRight) {

        let CustomLeft = custom.getCustomTypography(categoryTitleObj.titleLeftCustomObj, "left");
        let CustomCenter = custom.getCustomTypography(categoryTitleObj.titleCenterCustomObj, "center");
        let CustomRight = custom.getCustomTypography(categoryTitleObj.titleRightCustomObj, "right");

        htmlObj = <Grid key={"CatTitleBar1-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px", paddingRight: "8px", paddingLeft: "8px"}}>
            <div style={{width: '100%', display: 'flex', alignItems: "center"}}>
                <div style={{textAlign: 'left', width: '35%'}}>
                    {categoryTitleObj.useCustomLeft ? <CustomLeft>{categoryTitleObj.titleLeft}</CustomLeft> : ""}
                </div>
                <div style={{textAlign: 'center', width: '30%'}}>
                    {categoryTitleObj.useCustomCenter ? <CustomCenter>{categoryTitleObj.titleCenter}</CustomCenter> : ""}
                </div>
                <div style={{textAlign: 'right', width: '35%'}}>
                    {categoryTitleObj.useCustomRight ? <CustomRight>{categoryTitleObj.titleRight}</CustomRight> : ""}
                </div>
            </div>
        </Grid>;

    }
    else {

        let leftTitle = categoryTitleObj.titleLeftCustomObj.title;
        let centerTitle = categoryTitleObj.titleCenterCustomObj.title;
        let rightTitle = categoryTitleObj.titleRightCustomObj.title;

        // Used to help spacing on long titles where there is only one being used
        let leftTitleOnly    = (leftTitle !== "") && (centerTitle === "") && (rightTitle === "");
        let centerTitleOnly  = (leftTitle === "") && (centerTitle !== "") && (rightTitle === "");
        let rightTitleOnly   = (leftTitle === "") && (centerTitle === "") && (rightTitle !== "");
        let leftAndRightOnly = (leftTitle !== "") && (centerTitle === "") && (rightTitle !== "");

        if (leftTitleOnly) {

            let CustomLeft = custom.getCustomTypography(categoryTitleObj.titleLeftCustomObj, "left");

            htmlObj =
                <Grid key={"CatTitleBar2-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px", paddingRight: "8px", paddingLeft: "8px"}}>
                    <div style={{width: '100%', display: 'flex', alignItems: "center"}}>
                        <div style={{float: 'left', width: '100%', textAlign: 'left'}}>
                            <CustomLeft>{leftTitle}</CustomLeft>
                        </div>
                    </div>
                </Grid>;
        }
        else if (centerTitleOnly) {

            let CustomCenter = custom.getCustomTypography(categoryTitleObj.titleCenterCustomObj, "center");

            htmlObj =
                <Grid key={"CatTitleBar3-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px", paddingRight: "8px", paddingLeft: "8px"}}>
                    <div style={{width: '100%', display: 'flex', alignItems: "center"}}>
                        <div style={{float: 'right', width: '100%', textAlign: 'center'}}>
                            <CustomCenter>{centerTitle}</CustomCenter>
                        </div>
                    </div>
                </Grid>;
        }
        else if (rightTitleOnly) {

            let CustomRight = custom.getCustomTypography(categoryTitleObj.titleRightCustomObj, "right");

            htmlObj =
                <Grid key={"CatTitleBar4-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px", paddingRight: "8px", paddingLeft: "8px"}}>
                    <div style={{width: '100%', display: 'flex', alignItems: "center"}}>
                        <div style={{float: 'right', width: '100%', textAlign: 'right'}}>
                            <CustomRight>{categoryTitleObj.titleRight}</CustomRight>
                        </div>
                    </div>
                </Grid>;
        }
        else if (leftAndRightOnly) {

            let CustomLeft = custom.getCustomTypography(categoryTitleObj.titleLeftCustomObj, "left");
            let CustomRight = custom.getCustomTypography(categoryTitleObj.titleRightCustomObj, "right");

            htmlObj =
                <Grid key={"CatTitleBar5-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px", paddingRight: "8px", paddingLeft: "8px"}}>
                    <div style={{width: '100%', display: 'flex', alignItems: "center"}}>
                        <div style={{float: 'left', width: '50%'}}>
                            <CustomLeft>{leftTitle}</CustomLeft>
                        </div>
                        <div style={{float: 'right', width: '50%', textAlign: 'right'}}>
                            <CustomRight>{rightTitle}</CustomRight>
                        </div>
                    </div>
                </Grid>;
        }
        else {

            let CustomLeft = custom.getCustomTypography(categoryTitleObj.titleLeftCustomObj, "left");
            let CustomCenter = custom.getCustomTypography(categoryTitleObj.titleCenterCustomObj, "center");
            let CustomRight = custom.getCustomTypography(categoryTitleObj.titleRightCustomObj, "right");

            htmlObj =
                <Grid key={"CatTitleBar6-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, height: bHeight + "px", paddingRight: "8px", paddingLeft: "8px"}}>
                    <div style={{width: '100%', display: 'flex', alignItems: "center"}}>
                        <div style={{float: 'left', width: '35%'}}>
                            <CustomLeft>{leftTitle}</CustomLeft>
                        </div>
                        <div style={{float: 'center', width: '30%', textAlign: 'center'}}>
                            <CustomCenter>{centerTitle}</CustomCenter>
                        </div>
                        <div style={{float: 'right', width: '35%', textAlign: 'right'}}>
                            <CustomRight>{rightTitle}</CustomRight>
                        </div>
                    </div>
                </Grid>;
        }
    }

    return htmlObj;
}
