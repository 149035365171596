import * as font from "../utilities/fontUtilities";
import * as ddo from "../constants/customObjConstants";
import * as tag from "../constants/customMetadataConstants";
import * as portalShared from "../../portal/shared/metadataConstantsAndUtilities"
import * as mode from "./displayOneQuestionPerPage";


// Display object will define how a page is rendered
export const createJsonObjFromQuestionnaire = (questionnaire, buildMode, firstQuestionMode, surveyEditMode, surveyReadonlyHeaderMode, metadata) => {
    let qnr = JSON.parse(JSON.stringify(questionnaire));

    let isInstance = (buildMode === portalShared.INSTANT_MODE);

    let skipFirstQuestion = (firstQuestionMode === portalShared.SKIP_FIRST_QUESTION);

    let qNumObj = {
        answeredFirstQuestion: "show",
        useQuestionNumbers: false,
        questionNumberAppend: "",
        restartNumbersOnEachPage: false,
        subnumberLikertQuestions: "", // (Questionnaire) a or (a) or Xa or X.#   ...by default none
        subnumberShowgroupQuestions: "", // (Questionnaire) a or (a) or Xa or X.#  ...by default none
        currQuestionNumber: 0,
        currSubQuestionNumber: 0
    };

    if (tag.metadataExists(qnr, portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ)) {
        let mainPagesObj = tag.metadataValueObj(qnr, portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ);

        qNumObj = {
            useQuestionNumbers: mainPagesObj.questionNumbering !== "none",
            questionNumberAppend: mainPagesObj.questionNumbering === "all",
            restartNumbersOnEachPage: mainPagesObj.questionNumbering === "page",
            subnumberLikertQuestions: mainPagesObj.questionNumsLikert, // (Questionnaire) a or (a) or Xa or X.#   ...by default none
            subnumberShowgroupQuestions:mainPagesObj.questionNumsShowGroup, // (Questionnaire) a or (a) or Xa or X.#  ...by default none
        };

        skipFirstQuestion = (mainPagesObj.answeredFirstQuestion === "hide");
    }

    let displayDefObj = {};

    //let isQuestionnaireComplex = isInstance ? (qnr.questionCategories.length > 0) : (qnr.questionCategoryTemplates.length > 0);  // No type in instance so using questionCategories.length === 0 to determine complex vs simple

    //let isQuestionnaireStarted = isInstance ? qnr.started : false;  // Questionniare has been started, check with Fred for when this gets updated.

    // NOTE: Moved these declaration here because of bizarre issues of let not working.  var seemed to work.
    let pageObj = null;
    let sectionObj = null;
    //let likertGroupObj = null;
    let categories = null;
    let category = null;
    let groups = null;
    let group = null;
    let showGroupKey = "";
    let showGroupObjArray = []; // array of question answers showgroup objs {answerId: #, answerTemplateKey: "", showGroupKey: ""}
    let allShowGroupKeyArray = ddo.getAllShowGroupKeyArray(qnr); // array of ALL question answers showgroup objs {answerId: #, answerTemplateKey: "", showGroupKey: ""}
    //let containsShowGroupKey = "";
    //let showGroupKeyArray = ddo.getShowGroupKeyArray(qnr);
    let questions = null;
    let question = null;
    //let saveCurrQuestionNumber = 0;
    let oneQuestionPerPageModeFlag = oneQuestionPerPageMode(qnr, surveyEditMode);

    let startpage = false;

    displayDefObj = ddo.createDisplayDefObj(); // Create a display definition object

    startpage = addStartPageIfRequired(qnr, displayDefObj, (surveyEditMode === portalShared.READONLY_SURVEY));  // START PAGE

    pageObj = ddo.createPage();  // Get a new page

    // If URL parameter ?readonly=true then an additional parameter &header=true|false|filter can be used to display metadata header on survey, using URL will override any qnr metadata header mode tag.
    if (surveyEditMode === portalShared.READONLY_SURVEY) {

        // Check for questionnare metadata overrides for readonly display metadata header
        let qnrShowHeaderMode = tag.metadataExists(qnr, portalShared.HIDE_READONLY_HEADER);
        if (qnrShowHeaderMode === false) {
            qnrShowHeaderMode = tag.metadataExists(qnr, portalShared.SHOW_READONLY_HEADER);
            if (qnrShowHeaderMode === true) {
                qnrShowHeaderMode = "show";
            }
        }
        else {
            qnrShowHeaderMode = "hide";
        }

        if (surveyReadonlyHeaderMode === portalShared.READONLY_SURVEY_URL_SHOW_HEADER_NONE) {

            if (qnrShowHeaderMode === "show") {
                ddo.addSurveyMetadataSection(pageObj, metadata); // IF no URL header mode specified AND no qnr specified THEN show full header
            }
            else if (qnrShowHeaderMode !== "hide") {
                ddo.addSurveyFilteredMetadataSection(pageObj, metadata);  // qnr filtered is default
            }
        }
        else {
            if (surveyReadonlyHeaderMode === portalShared.READONLY_SURVEY_URL_SHOW_HEADER_TRUE) {
                ddo.addSurveyMetadataSection(pageObj, metadata);
            }
            if (surveyReadonlyHeaderMode === portalShared.READONLY_SURVEY_URL_SHOW_HEADER_FILTERED) {
                ddo.addSurveyFilteredMetadataSection(pageObj, metadata);
            }

        }
    }

    ddo.checkQuestionnaireForPageHeaderOrFootersAddSection(pageObj, qnr, "pageHeader");  // If any page headers then add to top of page

    // Questionnaire titles --------------------------------------------------------------------------------
    // If there was a START page, do not include questionnaire level titles (headers or footers) on first page
    if (!startpage) {
        ddo.checkObjForTitlesAndAddSection(pageObj, ddo.OBJ_TYPE.QUESTIONNAIRE, qnr, "", "", []); // No questionNumber or showGroup
    }

    // Category loop
    categories = isInstance ? qnr.questionCategories : qnr.questionCategoryTemplates;
    for (var c = 0; c < categories.length; c++) {

        category = categories[c];

        if (tag.metadataExists(category, portalShared.CATEGORY_NEW_PAGE) && (c !== 0) && (surveyEditMode !== portalShared.READONLY_SURVEY)) {

            completePageAddNextPrevButtonsAndFooters(qnr, displayDefObj, pageObj, startpage, qNumObj, (surveyEditMode === portalShared.READONLY_SURVEY)); // Also adds page to (DDO)

            pageObj = ddo.createPage();  // Get a new page object

            ddo.checkQuestionnaireForPageHeaderOrFootersAddSection(pageObj, qnr, "pageHeader");  // If any page headers then add to top of page
        }


        // Category Titles ---------------------------------------------------------------------------------
        ddo.checkObjForTitlesAndAddSection(pageObj, ddo.OBJ_TYPE.CATEGORY, category, "", "", []); // Category titles

        // Group loop
        groups = isInstance ? category.questionGroupIds : category.groupTemplates; // Instance is just ID's
        groups = groups === undefined ? [] : groups;
        for (var g = 0; g < groups.length; g++) {

            // When in instance mode groups[g] is the group ID, in template mode its the full group template
            group = isInstance ? ddo.getGroup(qnr, groups[g]) : groups[g]; // Get group assiociated with the Group ID

            // If TEMPLATE Mode and the Group is not visible then skip (!isInstance)&&(!group.visible)
            // If group is NOT a show group then processs it.  Show grroups are processed as part of the answer that references it
            if (!allShowGroupKeyArray.includes(group.templateKey)) {

                // NOTE: Show group visible == false if currently not visible, However: visible can be true, if related answer is true, so check needs to be for both
                //showGroupKey = (!group.visible || showGroupKeyArray.includes(group.templateKey)) ? group.templateKey : ""; // For templates always show a showGroup

                // LIKERT Check TODO - Not sure why there is a group check here for metadata ZZZZZ  || (tag.metadataExists(group, portalShared.DISPLAY_SINGLE_SELECT_AS_LIKERT))
                if ((isInstance ? group.groupType : group.questionGroupType) === "LIKERT") {

                    if ((c === 0) && (g === 0) && (skipFirstQuestion) && (isInstance)) {
                        // First question answered outside portal so skip
                    } else {
                        addLikertGroupAndQuestions(isInstance, qnr, group, pageObj, sectionObj, qNumObj, showGroupKey, showGroupObjArray);

                        let likertGroupAnswers = [];
                        if (isInstance && group.questionIds.length > 0) {
                            // Find the first question in the LIKERT group and get the answers for it
                            likertGroupAnswers = qnr.questions.filter(function(e) {return e.id === group.questionIds[0]});  // eslint-disable-line
                        }

                        showGroupObjArray = getShowGroupObjArray(isInstance, isInstance ? likertGroupAnswers[0].answerOptions : group.answerOptionTemplates);

                        if (showGroupObjArray.length > 0) {

                            addShowGroupToSurvey(isInstance, qnr, category, groups, showGroupObjArray, pageObj, sectionObj, qNumObj);
                        }

                        if (oneQuestionPerPageModeFlag) {
                            completePageAddNextPrevButtonsAndFooters(qnr, displayDefObj, pageObj, startpage, qNumObj, (surveyEditMode === portalShared.READONLY_SURVEY)); // Also adds page to (DDO)
                            pageObj = ddo.createPage();  // Get a new page object
                            ddo.checkQuestionnaireForPageHeaderOrFootersAddSection(pageObj, qnr, "pageHeader");  // If any page headers then add to top of page
                        }
                    }

                } else {

                    // Group titles ----------------------------------------------------------------------------
                    ddo.checkObjForTitlesAndAddSection(pageObj, ddo.OBJ_TYPE.GROUP, group, qNumObj, showGroupKey, showGroupObjArray);

                    questions = isInstance ? group.questionIds : group.questionTemplates;

                    questions = (questions === undefined) ? [] : questions;  // In case there are no questions create empty array to avoid errors

                    // Plain Questions, if only one question in the group then the group can have a question number
                    //saveCurrQuestionNumber = qNumObj.currQuestionNumber;

                    // Question ID loop
                    for (var q = 0; q < questions.length; q++) {

                        if ((c === 0) && (g === 0) && (q === 0) && (skipFirstQuestion) && (isInstance)) {
                            // First question answered outside portal so skip
                        } else {
                            question = isInstance ? ddo.getQuestion(qnr, questions[q]) : questions[q];  // Get question associated with Question ID, INSTANCE is just ID, TEMPLATE is question obj

                            showGroupObjArray = getShowGroupObjArray(isInstance, isInstance ? question.answerOptions : question.answerOptionTemplates);

                            // if ((saveCurrQuestionNumber !== qNumObj.currQuestionNumber) && (showGroupKey === "")) {
                            //     qNumObj.currQuestionNumber -= 1;
                            // }

                            ddo.checkObjForTitlesAndAddSection(pageObj, ddo.OBJ_TYPE.QUESTION, question, qNumObj, showGroupKey, showGroupObjArray); // Question Titles and question added

                            //let showGroupKeyArray = templateModeAddShowGroup(qnr, category, group, question);
                            if (showGroupObjArray.length > 0) {

                                addShowGroupToSurvey(isInstance, qnr, category, groups, showGroupObjArray, pageObj, sectionObj, qNumObj);
                            }
                        }

                        if (oneQuestionPerPageModeFlag && (q !== questions.length-1) ) {
                            completePageAddNextPrevButtonsAndFooters(qnr, displayDefObj, pageObj, startpage, qNumObj, (surveyEditMode === portalShared.READONLY_SURVEY)); // Also adds page to (DDO)
                            pageObj = ddo.createPage();  // Get a new page object
                            ddo.checkQuestionnaireForPageHeaderOrFootersAddSection(pageObj, qnr, "pageHeader");  // If any page headers then add to top of page
                        }

                        // if (!isInstance) {
                        //     // TEMPLATE MODE: IF an answer references a showGroup then we need to add the group here to be in the right position in survey
                        //     let showGroupKeyArray = templateModeAddShowGroup(qnr, category, group, question);
                        //     if (showGroupKeyArray.length > 0) {
                        //
                        //         addShowGroupToSurvey(isInstance, qnr, showGroupKeyArray, pageObj, sectionObj, qNumObj);
                        //     }
                        // }
                    }
                }
            }
        }
    }

    addSubmitButtonToLastPageOfSurvey(qnr, displayDefObj, pageObj, startpage); // Add last page of survey to (DDO)

    addClosePageIfRequired(qnr, displayDefObj); // Add CLOSE page to (DDO) - COMPLEX Questionnaire - Full Page

    return displayDefObj;
};


export const containsShowGroup = (answers) => {

    let showGroup = "";

    // NOTE: Currently we only consider a question can only have a single show group
    for (let i = 0; i < answers.length; i++) {
        if ((answers[i].groupKeys.length > 0) && (answers[i].groupKeys[0] !== "")) {
            showGroup = answers[i].groupKeys[0];
        }
    }

    return showGroup;
}


export const getShowGroupObjArray = (isInstance, answers) => {

    let showGroupObjArray = [];  // Note: each answer can have a different show group
    let showGroupKey = null;  // Note: A single answer is now restricted to a single show group

    if (answers !== undefined) {
        for (let i = 0; i < answers.length; i++) {

            showGroupKey = isInstance ? (answers[i].groupKeys.length > 0 ? answers[i].groupKeys[0] : "") : (answers[i].importGroupKeys.length > 0 ? answers[i].importGroupKeys[0] : ""); // Note: A single anser is NOW restricted to a single show group, structure is still an array

            if (showGroupKey !== "") {
                // Only add distinct show group keys
                if (!showGroupObjArray.some(e => e.showGroupKey === showGroupKey)) { // eslint-disable-line
                    showGroupObjArray.push({answerId: answers[i].id, answerKey: answers[i].templateKey, showGroupKey: showGroupKey}); // eslint-disable-line
                }
            }
        }
    }

    return showGroupObjArray;
}


export const oneQuestionPerPageMode = (qnr, surveyEditMode) => {

    let oneQuestionPerPageFlag = false;

    // If NO main pages custom object then use full page
    if (tag.metadataExistsObj(portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ)) {
        if ((surveyEditMode === portalShared.READONLY_SURVEY) || (tag.metadataExistsInObj(qnr, portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ, "pagingMode", "full"))) {

            oneQuestionPerPageFlag = false; // If readonly or override to full page always show full page
        } else {

            // Check to see if full page or one question per page format is specified or required by screen size
            if ((tag.metadataExistsInObj(qnr, portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ, "pagingMode", "one") || mode.displayOneQuestionPerPage())) {
                oneQuestionPerPageFlag = true;
            }

        }
    }

    return oneQuestionPerPageFlag;
};


export const addStartPageIfRequired = (qnr, displayDefObj, readonly) => {

    let startpage = false;

    // Start page title order, NAME, SCRIPT, DEFAULT TEXT, START BUTTON
    // Start page is built with NO id ref, instead all has the embedded text to prevent needing this code again.

    if (tag.metadataExists(qnr, portalShared.CUSTOM_QNR_START_PAGE_OBJ) && !readonly) {

        let startPageObj = tag.metadataValueObj(qnr, portalShared.CUSTOM_QNR_START_PAGE_OBJ);

        startpage = true;

        let pageObj = ddo.createPage();  // Get a new page object

        let sectionObj = ddo.createPageSection(ddo.SECTION.JUSTIFY.CENTER, ddo.SECTION.ALIGN_ITEMS.STRETCH, ddo.SECTION.STYLE_TYPE.ONE, ddo.SECTION.CLASS_TYPE.CONTROL);  // Get a new section object

        // If questionnaire name is not hidden then show it on start page
        // Adding Portal Display HIDE mode to questionnaire name will NOT hide it from start page
        if (!startPageObj.hideName) {
            if (tag.metadataExists(qnr, portalShared.CUSTOM_DDO_NAME)) {
                let customNameObj = tag.metadataValue(qnr, portalShared.CUSTOM_DDO_NAME);

                if (customNameObj === "hide") {
                    let nameVariant = font.getTextDefaultVariant(ddo.OBJ_TYPE.QUESTIONNAIRE, ddo.OBJ_TEXT_TYPE.NAME);
                    ddo.addTitleToSection(sectionObj, ddo.TITLE.FORMAT.PLAIN, ddo.TITLE.SOURCE.NONE, ddo.TITLE.ID.NONE, qnr.name, nameVariant);
                }
                else {
                    ddo.addTitleToSection(sectionObj, ddo.TITLE.FORMAT.CUSTOM, ddo.TITLE.SOURCE.NONE, ddo.TITLE.ID.NONE, qnr.name, customNameObj);
                }
            }
            else {
                let nameVariant = font.getTextDefaultVariant(ddo.OBJ_TYPE.QUESTIONNAIRE, ddo.OBJ_TEXT_TYPE.NAME);
                ddo.addTitleToSection(sectionObj, ddo.TITLE.FORMAT.PLAIN, ddo.TITLE.SOURCE.NONE, ddo.TITLE.ID.NONE, qnr.name, nameVariant);
            }
        }

        // There cannot be a start page script entered without text
        if (tag.metadataExists(qnr, portalShared.QNR_START_PAGE_SCRIPT)) {

            // If the metadata script exist there will be text
            let scriptText = tag.metadataValue(qnr, portalShared.QNR_START_PAGE_SCRIPT);
            let customScriptTextObj = null;

            if (tag.metadataExists(qnr, portalShared.QNR_START_PAGE_SCRIPT_FORMAT_OBJ)) {

                if (tag.metadataExists(qnr, portalShared.QNR_START_PAGE_SCRIPT_FORMAT_OBJ)) {
                    customScriptTextObj = tag.metadataValueObj(qnr, portalShared.QNR_START_PAGE_SCRIPT_FORMAT_OBJ);
                }
            }

            // Determine if start page script is PLAIN or CUSTOM
            if (customScriptTextObj === null) {
                let scriptVariant = font.getTextDefaultVariant(ddo.OBJ_TYPE.QUESTIONNAIRE, ddo.OBJ_TEXT_TYPE.START_PAGE_SCRIPT);
                ddo.addTitleToSection(sectionObj, ddo.TITLE.FORMAT.PLAIN, ddo.TITLE.SOURCE.NONE, ddo.TITLE.ID.NONE, scriptText, scriptVariant);
            }
            else {
                ddo.addTitleToSection(sectionObj, ddo.TITLE.FORMAT.CUSTOM, ddo.TITLE.SOURCE.NONE, ddo.TITLE.ID.NONE, scriptText, customScriptTextObj);
            }

        }

        // Make sure the default text has not been blanked out
        if (startPageObj.defaultText !== "") {

            // Determine if start page text is PLAIN or CUSTOM
            if (startPageObj.defaultTextFormat === "default") {
                let defaultTextVariant = font.getTextDefaultVariant(ddo.OBJ_TYPE.QUESTIONNAIRE, ddo.OBJ_TEXT_TYPE.START_PAGE_TEXT);
                ddo.addTitleToSection(sectionObj, ddo.TITLE.FORMAT.PLAIN, ddo.TITLE.SOURCE.NONE, ddo.TITLE.ID.NONE, startPageObj.defaultText, defaultTextVariant);
            }
            else {
                ddo.addTitleToSection(sectionObj, ddo.TITLE.FORMAT.CUSTOM, ddo.TITLE.SOURCE.NONE, ddo.TITLE.ID.NONE, startPageObj.defaultText, startPageObj.defaultTextCustomObj);
            }
        }

        // Add a new section for the START button
        ddo.addSectionToPage(pageObj, sectionObj);

        sectionObj = ddo.createPageSection(ddo.SECTION.JUSTIFY.SPACE_AROUND, ddo.SECTION.ALIGN_ITEMS.FLEX_END, ddo.SECTION.STYLE_TYPE.NONE, ddo.SECTION.CLASS_TYPE.FOOTER);  // Get a new section object

        // ddo.addButtonToSection(sectionObj, ddo.BUTTON.MODE.CANCEL, ddo.BUTTON.VARIANT.TEXT, ddo.BUTTON.COLOR.SECONDARY, ddo.BUTTON.NAME.DEFAULT);
        ddo.addButtonToSection(sectionObj, ddo.BUTTON.MODE.START, ddo.BUTTON.VARIANT.TEXT, ddo.BUTTON.COLOR.PRIMARY, ddo.BUTTON.NAME.DEFAULT);

        ddo.addSectionToPage(pageObj, sectionObj);
        ddo.addPageToDisplayDefObj(displayDefObj, pageObj);
    }

    return startpage;
}


export const completePageAddNextPrevButtonsAndFooters = (qnr, displayDefObj, pageObj, startPage, qNumObj, readonly) => {

    if (!readonly) {
        if (qNumObj.restartNumbersOnEachPage) {
            qNumObj.currQuestionNumber = 0;
        }

        let sectionObj = ddo.createPageSection(ddo.SECTION.JUSTIFY.SPACE_AROUND, ddo.SECTION.ALIGN_ITEMS.FLEX_END, ddo.SECTION.STYLE_TYPE.NONE, ddo.SECTION.CLASS_TYPE.FOOTER);  // Get a new section object

        let pagesStr = ddo.OBJ.KEY.DDO.PAGE_ARRAY;  // Needed for debugging

        // If NOT first page, then also add a PREVIOUS button
        if (displayDefObj[pagesStr].length !== 0) {
            // If there was a start page then page 2 will NOT have a prev. button
            if (startPage && (displayDefObj[pagesStr].length !== 1)) {
                ddo.addButtonToSection(sectionObj, ddo.BUTTON.MODE.PREVIOUS, ddo.BUTTON.VARIANT.TEXT, ddo.BUTTON.COLOR.SECONDARY, ddo.BUTTON.NAME.DEFAULT);
            } else {
                if (displayDefObj[pagesStr].length >= 1) {
                    ddo.addButtonToSection(sectionObj, ddo.BUTTON.MODE.PREVIOUS, ddo.BUTTON.VARIANT.TEXT, ddo.BUTTON.COLOR.SECONDARY, ddo.BUTTON.NAME.DEFAULT);
                }
            }
        }
        ddo.addButtonToSection(sectionObj, ddo.BUTTON.MODE.NEXT, ddo.BUTTON.VARIANT.TEXT, ddo.BUTTON.COLOR.PRIMARY, ddo.BUTTON.NAME.DEFAULT);

        ddo.addSectionToPage(pageObj, sectionObj);

        ddo.checkQuestionnaireForPageHeaderOrFootersAddSection(pageObj, qnr, "pageFooter1");  // If any page footers then add to bottom of page
        ddo.checkQuestionnaireForPageHeaderOrFootersAddSection(pageObj, qnr, "pageFooter2");  // If any page footers then add to bottom of page

        ddo.addPageToDisplayDefObj(displayDefObj, pageObj);
    }
};


export const addLikertGroupAndQuestions = (isInstance, qnr, group, pageObj, sectionObj, qNumObj, showGroupKey, showGroupObjArray = []) => {

    // Group titles ----------------------------------------------------------------------------
    ddo.checkObjForTitlesAndAddSection(pageObj, ddo.OBJ_TYPE.GROUP, group, qNumObj, showGroupKey, showGroupObjArray);

    sectionObj = ddo.createPageSection(ddo.SECTION.JUSTIFY.CENTER, ddo.SECTION.ALIGN_ITEMS.STRETCH, ddo.SECTION.STYLE_TYPE.ONE, ddo.SECTION.CLASS_TYPE.CONTROL);  // Get a new section object

    let likertGroupObj = ddo.createLikertGroupObj(group.id);

    qNumObj.currSubQuestionNumber = 0;

    // Plain Question ID loop
    let questions = (isInstance) ? group.questionIds : group.questionTemplates;
    for (var q = 0; q < questions.length; q++) {

        // If instance mode questions is an array of ID's, in template mode its an array of question templates
        let question = (isInstance) ? ddo.getQuestion(qnr, questions[q]) : questions[q];  // Get question associated with Question ID, INSTANCE is just ID, TEMPLATE is question obj

        showGroupObjArray = getShowGroupObjArray(isInstance, isInstance ? question.answerOptions : question.answerOptionTemplates);

        // if (showGroupKey === "") {
        //     showGroupKey = (showGroupObjArray.length > 0) ? showGroupObjArray[0].groupKey : ""; // ToDo NOTE: ZZZZZ Pulls first show group in array not allowing a LIKERT question to have different show groups
        // }

        ddo.addLikertQuestionToLikertGroup(likertGroupObj, question.id, qNumObj, showGroupKey, showGroupObjArray);
    }

    ddo.addLikertGroupToSection(sectionObj, likertGroupObj);

    ddo.addSectionToPage(pageObj, sectionObj);
};


export const addSubmitButtonToLastPageOfSurvey = (qnr, displayDefObj, pageObj, startPage) => {
    if (!tag.metadataExists(qnr, portalShared.NO_SUBMIT)) {

        // create a final section for the last page of survey, include a SUBMIT button
        let sectionObj = ddo.createPageSection(ddo.SECTION.JUSTIFY.SPACE_AROUND, ddo.SECTION.ALIGN_ITEMS.FLEX_END, ddo.SECTION.STYLE_TYPE.NONE, ddo.SECTION.CLASS_TYPE.FOOTER);  // Get a new section object

        let pagesStr = ddo.OBJ.KEY.DDO.PAGE_ARRAY;  // Needed for debugging

        // If NOT first page, then also add a PREVIOUS button
        if (displayDefObj[pagesStr].length !== 0) {
            if ((startPage && displayDefObj[pagesStr].length !== 1)||(!startPage && displayDefObj[pagesStr].length > 0)) {
                ddo.addButtonToSection(sectionObj, ddo.BUTTON.MODE.PREVIOUS, ddo.BUTTON.VARIANT.TEXT, ddo.BUTTON.COLOR.SECONDARY, ddo.BUTTON.NAME.DEFAULT);
            }
        }

        ddo.addButtonToSection(sectionObj, ddo.BUTTON.MODE.FINISH, ddo.BUTTON.VARIANT.TEXT, ddo.BUTTON.COLOR.PRIMARY, ddo.BUTTON.NAME.DEFAULT);

        ddo.addSectionToPage(pageObj, sectionObj);
    }

    ddo.checkQuestionnaireForPageHeaderOrFootersAddSection(pageObj, qnr, "pageFooter1");  // If any page footers then add to bottom of page
    ddo.checkQuestionnaireForPageHeaderOrFootersAddSection(pageObj, qnr, "pageFooter2");  // If any page footers then add to bottom of page

    ddo.addPageToDisplayDefObj(displayDefObj, pageObj); // Add last page of survey to (DDO)

};


export const addClosePageIfRequired = (qnr, displayDefObj) => {

    // Close page title order, ICON, SCRIPT, DEFAULT TEXT
    // Close page is built with NO id ref, instead all has the embedded text to prevent needing this code again.

    let legacyMode = (tag.metadataExists(qnr, portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ_LEGACY)); // LEGACY FIX

    let legacyClosePageObj = {
        noGreenCheck: false,
        defaultText: portalShared.QNR_CLOSE_PAGE_DEFAULT_TEXT,
        defaultTextFormat: "default"
    }

    // If legacy close page or current close page metadata object, add close page
    if (tag.metadataExistsObj(qnr, portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ) || legacyMode) {

        let closePageObj = null;
        if (legacyMode) {
            closePageObj = legacyClosePageObj;
        }
        else {
            closePageObj = tag.metadataValueObj(qnr, portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ);
        }


        let pageObj = ddo.createPage();  // Get a new page object

        let sectionObj = ddo.createPageSection(ddo.SECTION.JUSTIFY.CENTER, ddo.SECTION.ALIGN_ITEMS.STRETCH, ddo.SECTION.STYLE_TYPE.ONE, ddo.SECTION.CLASS_TYPE.CONTROL);  // Get a new section object

        if (!closePageObj.noGreenCheck) {
            let iconObj = ddo.createPageSection(ddo.SECTION.JUSTIFY.CENTER, ddo.SECTION.ALIGN_ITEMS.STRETCH, ddo.SECTION.STYLE_TYPE.ONE, ddo.SECTION.CLASS_TYPE.CONTROL);  // Get a new section object
            ddo.addIconToSection(iconObj, ddo.ICON_TYPE.GREEN_CHECK_ICON);
            ddo.addSectionToPage(pageObj, iconObj);
        }

        let scriptText = "";

        // There cannot be a close page script entered without text
        if (tag.metadataExistsObj(qnr, portalShared.QNR_CLOSE_PAGE_SCRIPT)) {

            // If the metadata script exist there will be text
            scriptText = tag.metadataValueObj(qnr, portalShared.QNR_CLOSE_PAGE_SCRIPT);
            let customScriptTextObj = null;

            if (tag.metadataExistsObj(qnr, portalShared.QNR_CLOSE_PAGE_SCRIPT_FORMAT_OBJ)) {

                if (tag.metadataExistsObj(qnr, portalShared.QNR_CLOSE_PAGE_SCRIPT_FORMAT_OBJ)) {
                    customScriptTextObj = tag.metadataValueObj(qnr, portalShared.QNR_CLOSE_PAGE_SCRIPT_FORMAT_OBJ);
                }
            }

            // Determine if start page script is PLAIN or CUSTOM
            if (customScriptTextObj === null) {
                let scriptVariant = font.getTextDefaultVariant(ddo.OBJ_TYPE.QUESTIONNAIRE, ddo.OBJ_TEXT_TYPE.CLOSE_PAGE_SCRIPT);
                ddo.addTitleToSection(sectionObj, ddo.TITLE.FORMAT.PLAIN, ddo.TITLE.SOURCE.NONE, ddo.TITLE.ID.NONE, scriptText, scriptVariant);
            }
            else {
                ddo.addTitleToSection(sectionObj, ddo.TITLE.FORMAT.CUSTOM, ddo.TITLE.SOURCE.NONE, ddo.TITLE.ID.NONE, scriptText, customScriptTextObj);
            }

        }

        // Make sure the default text has not been blanked out unless there is script text
        if (closePageObj.defaultText !== "" || scriptText !== "") {

            if (closePageObj.defaultText !== "") {
                // Determine if start page text is PLAIN or CUSTOM
                if (closePageObj.defaultTextFormat === "default") {
                    let defaultTextVariant = font.getTextDefaultVariant(ddo.OBJ_TYPE.QUESTIONNAIRE, ddo.OBJ_TEXT_TYPE.CLOSE_PAGE_TEXT);
                    ddo.addTitleToSection(sectionObj, ddo.TITLE.FORMAT.PLAIN, ddo.TITLE.SOURCE.NONE, ddo.TITLE.ID.NONE, closePageObj.defaultText, defaultTextVariant);
                } else {
                    ddo.addTitleToSection(sectionObj, ddo.TITLE.FORMAT.CUSTOM, ddo.TITLE.SOURCE.NONE, ddo.TITLE.ID.NONE, closePageObj.defaultText, closePageObj.defaultTextCustomObj);
                }
            }

            ddo.addSectionToPage(pageObj, sectionObj);
        }

        ddo.addPageToDisplayDefObj(displayDefObj, pageObj);
    }
};


export const templateModeAddShowGroup = (qnr, category, group, question) => {

    let showGroupKeyArray = [];

    let answers = question.answerOptionTemplates;

    if (answers !== undefined) {

        for (var a = 0; a < answers.length; a++) {

            let importGroupKeys = answers[a].importGroupKeys;

            if (importGroupKeys !== undefined) {

                for (var k = 0; k < importGroupKeys.length; k++) {

                    let showGroupTemplateKey = importGroupKeys[k];
                    if (!showGroupKeyArray.includes(showGroupTemplateKey)) {

                        showGroupKeyArray.push(showGroupTemplateKey);
                    }
                }
            }
        }
    }

    return showGroupKeyArray;
};


export const addShowGroupToSurvey = (isInstance, qnr, category, groups, showGroupObjArray, pageObj, sectionObj, qNumObj) => {

    // let categories = categoriesArray;
    // let groups = groupsArray;
    let questions = null;
    let showGroupKey = "";
    //let showGroupObjArray = [];

    for (var sgo = 0; sgo < showGroupObjArray.length; sgo++) {

        //for (var c = 0; c < categories.length; c++) {

        for (var g = 0; g < groups.length; g++) {

            let group = isInstance ? ddo.getGroup(qnr, groups[g]) : groups[g]; // Get group assiociated with the Group ID, in instance mode groups is just an array if IDs;

            // Find the group to display
            if (showGroupObjArray[sgo].showGroupKey === group.templateKey) {

                showGroupKey = group.templateKey;

                // LIKERT Check
                if ((isInstance ? group.groupType : group.questionGroupType) === "LIKERT") {

                    addLikertGroupAndQuestions(isInstance, qnr, group, pageObj, sectionObj, qNumObj, showGroupKey, showGroupObjArray);
                }
                else {
                    questions = isInstance ? group.questionIds : group.questionTemplates;

                    questions = (questions === undefined) ? [] : questions;  // In case there are no questions create empty array to avoid errors

                    // Plain Questions, if only one question in the group then the group can have a question number
                    ddo.checkObjForTitlesAndAddSection(pageObj, ddo.OBJ_TYPE.GROUP, group, (questions.length === 1 ? qNumObj : ""), showGroupKey, showGroupObjArray); // Group titles QZZZZZ

                    // Question ID loop
                    for (var q = 0; q < questions.length; q++) {

                        let question = isInstance ? ddo.getQuestion(qnr, questions[q]) : questions[q];  // Get question associated with Question ID, INSTANCE is just ID, TEMPLATE is question obj;

                        ddo.checkObjForTitlesAndAddSection(pageObj, ddo.OBJ_TYPE.QUESTION, question, qNumObj, showGroupKey, showGroupObjArray); // Question Titles and question added QZZZZZ
                    }
                }

                break;
            }
        }
        //}

    }
};


// export const addShowGroupToSurvey = (isInstance, qnr, categories, groups, showGroupObjArray, pageObj, sectionObj, qNumObj) => {
//
//     // let categories = categoriesArray;
//     // let groups = groupsArray;
//     let questions = null;
//     let showGroupKey = "";
//     //let showGroupObjArray = [];
//
//     for (var c = 0; c < categories.length; c++) {
//
//         for (var g = 0; g < groups.length; g++) {
//
//             let group = isInstance ? ddo.getGroup(qnr, groups[g]) : groups[g]; // Get group assiociated with the Group ID, in instance mode groups is just an array if IDs;
//
//             // Find the group to display
//             if (showGroupObjArray.some(e => e.showGroupKey === group.templateKey)) {
//
//                 showGroupKey = group.templateKey;
//
//                 // LIKERT Check
//                 if ((isInstance ? group.groupType : group.questionGroupType) === "LIKERT") {
//
//                     addLikertGroupAndQuestions(isInstance, qnr, group, pageObj, sectionObj, qNumObj, showGroupKey, showGroupObjArray);
//                 }
//                 else {
//                     questions = isInstance ? group.questionIds : group.questionTemplates;
//
//                     questions = (questions === undefined) ? [] : questions;  // In case there are no questions create empty array to avoid errors
//
//                     // Plain Questions, if only one question in the group then the group can have a question number
//                     ddo.checkObjForTitlesAndAddSection(pageObj, ddo.OBJ_TYPE.GROUP, group, (questions.length === 1 ? qNumObj : ""), showGroupKey, showGroupObjArray); // Group titles
//
//                     // Question ID loop
//                     for (var q = 0; q < questions.length; q++) {
//
//                         let question = isInstance ? ddo.getQuestion(qnr, questions[q]) : questions[q];  // Get question associated with Question ID, INSTANCE is just ID, TEMPLATE is question obj;
//
//                         ddo.checkObjForTitlesAndAddSection(pageObj, ddo.OBJ_TYPE.QUESTION, question, qNumObj, showGroupKey, showGroupObjArray); // Question Titles and question added
//                     }
//                 }
//             }
//         }
//     }
// };


// See above
//if (showGroupObjArray.some(e => e.showGroupKey === group.templateKey)) {
// Note: If you need compatibility with lousy browsers then your best bet is:
// if (vendors.filter(function(e) { return e.Name === 'Magenic'; }).length > 0) {
//     /* vendors contains the element we're looking for */
// }

