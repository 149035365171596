import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
  import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { Button } from "@mui/material";
import {STORAGE_IS_SSO} from "../../portal/constants/systemConstants";
  function ProtectedComponent({setToken}) {
    const { instance, inProgress, accounts } = useMsal();    
    
    useEffect(() => {
      const accessTokenRequest = {
        scopes: ["user.read"],
        account: accounts[0],
      };
      if ( inProgress === InteractionStatus.None) {
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            let idToken = accessTokenResponse.idToken;
            // Call your API with token
            instance.setActiveAccount(accounts[0]);
            setToken(idToken);
            localStorage.setItem(STORAGE_IS_SSO,"true");
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(accessTokenRequest);
            }
            console.log(error);
          });
      }
    }, [instance, accounts, inProgress]);
  
    return  <Button id="SSO"    
    name="SSO"
    variant="contained"
    primary="true"
    onClick={()=>{
        const loginRequest = {
            scopes: ["User.Read"],
            AccessTokenLifetime:"8:00:00"

        };
        instance.loginRedirect(loginRequest).catch(e => {
        console.log(e);
        });
        }
    }
    
>SSO</Button>;
  }
  
  export default function ssoButton(setToken) {
    return (
     
        <ProtectedComponent setToken={setToken}/>
 
    );
  }